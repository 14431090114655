/* eslint-disable react/jsx-no-literals */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Pharmacy } from '@aposphaere/core-kit'
import { InfoWindow } from '@react-google-maps/api'
import React, { useMemo, useEffect, useState } from 'react'
import QuickActions from './QuickActions'
import { CloseIcon, IconButton, IconButtonKind, InfoIcon, CheckIcon } from '@aposphaere/ui-components'
import QuickActionsLegend from '../QuickActionsLegend'
import { usePharmaciesQuery, usePharmacyQuery } from '../../hooks/graphql'
import { InfoContainerTabs } from './InfoContainerTabs'
import { useRoutePlanner } from '../../contexts/routePlannerContext'
import ratAndTatLogo from '../RatundTat.png'
import { useAddCallToPharmacy } from '../../hooks/graphql'

type gskDataType = { isApoTrainer: boolean; class: string; BM: string }

interface Props {
  setSelectedPharmacy: (id: number | undefined) => void
  selectedPharmacyId: number | undefined
  onClose: () => void
  onCreateAppointmentClick: () => void
  onPharmacyDetailClick: () => void
  routeForAppointment: boolean
}

const InfoWindowContainer = ({
  onCreateAppointmentClick,
  onPharmacyDetailClick,
  setSelectedPharmacy,
  selectedPharmacyId,
  onClose,
  routeForAppointment,
}: Props) => {
  const { data: pharmacies } = usePharmaciesQuery()
  const { data: activePharmacy, refetch } = usePharmacyQuery(selectedPharmacyId)
  const { sortedAppointmentsToday } = useRoutePlanner()
  const initialPharmacy: null | Pharmacy = useMemo(() => {
    if (activePharmacy || !pharmacies?.length || !selectedPharmacyId) {
      return null
    }

    return pharmacies.find(({ id }) => id === selectedPharmacyId) ?? null
  }, [activePharmacy, pharmacies, selectedPharmacyId])

  useEffect(() => {
    setShowQuickActionsLegend(false)
  }, [selectedPharmacyId])

  useEffect(() => {
    refetch().catch((err) => console.error(err))
  }, [pharmacies, refetch])

  const pharmacyAdditionalData = activePharmacy?.additional_data

  const [showQuickActionsLegend, setShowQuickActionsLegend] = useState(false)
  const { mutate: addCallToPharmacyId } = useAddCallToPharmacy()

  const gskData = useMemo(() => {
    if (!pharmacyAdditionalData) {
      return null
    }
    try {
      const parsedAdditionalData = JSON.parse(pharmacyAdditionalData)?.gsk as gskDataType

      return parsedAdditionalData
    } catch (error) {
      console.error('failed to parse activePharmacy additonal_data:', error)
    }
  }, [pharmacyAdditionalData])

  const pharmacy = activePharmacy || initialPharmacy

  const lat = pharmacy?.address.latitude ?? 0
  const lng = pharmacy?.address.longitude ?? 0
  return (
    <React.Fragment>
      {pharmacy ? (
        <InfoWindow
          position={{
            lng: lng,
            lat: lat,
          }}
          options={{ pixelOffset: { height: -70, width: 0 } }}
          onCloseClick={onClose}
        >
          <div className="relative">
            <div className="z-9999 right-0 bottom-0 absolute">
              {' '}
              <IconButton
                kind={IconButtonKind.custom}
                additionalCss="h-6 w-6 text-blue-700 hover:bg-blue-200 focus:border-gray-300 active:text-blue-800 focus:outline-none transition ease-in-out duration-150"
                icon={<InfoIcon />}
                onClick={() => setShowQuickActionsLegend((prev) => !prev)}
              />
            </div>
            {showQuickActionsLegend ? (
              <QuickActionsLegend />
            ) : (
              <div>
                <div className="flex justify-between mb-3">
                  <div>
                    <p>{`ID: ${pharmacy.id} / ${pharmacy.okid || ''}`}</p>
                    <p>campus: {pharmacy.campus_registration_code}</p>
                    <p className="font-medium mb-0.5 mt-3">{pharmacy.name}</p>
                    <p>{pharmacy.address?.address_name}</p>
                    <p className="mb-3">{`${pharmacy.address?.zipcode || ''} ${pharmacy.address.city || ''}`}</p>
                    <a
                      className="font-medium mb-0.5"
                      href={`tel:${pharmacy.phone}`}
                      onClick={async () => {
                        await addCallToPharmacyId({ pharmacy_id: pharmacy.id.toString() })
                      }}
                    >
                      {`Tel.: ${pharmacy.phone || '_'}`}
                    </a>
                    {pharmacy.email && (
                      <p>
                        <a aria-disabled={!pharmacy?.email} href={pharmacy.email ? `mailto:${pharmacy.email}` : undefined}>
                          <span className="font-medium">{'E-Mail: '}</span>
                          <span className=" cursor-pointer">{pharmacy.email}</span>
                        </a>
                      </p>
                    )}
                    {pharmacy.contact_person && (
                      <p>
                        <span className="font-medium">{'Ansprechpartner: '}</span>
                        {pharmacy.contact_person}
                      </p>
                    )}
                    <br />
                    {!!pharmacy.url && (
                      <p className="w-full break-words text-blue-700 underline">
                        <a className="cursor-pointer" href={pharmacy.url}>
                          {pharmacy.url}
                        </a>
                      </p>
                    )}
                    {!!pharmacy.facebook && (
                      <p className="w-full break-words text-blue-700 underline">
                        <a className="cursor-pointer" href={pharmacy.facebook}>
                          Facebook
                        </a>
                      </p>
                    )}
                  </div>
                  <div className="px-3">
                    {pharmacy.rat_tat && <img src={ratAndTatLogo} alt="Rat and Tat Logo" className="w-16 h-20"/>}
                  </div>
                </div>
                <InfoContainerTabs activePharmacy={activePharmacy} setSelectedPharmacy={setSelectedPharmacy} />
                <hr className="mt-2" />
                <QuickActions pharmacy={pharmacy} onOpenPharmacyDetails={onPharmacyDetailClick} onCreateAppointmentClick={onCreateAppointmentClick} />
              </div>
            )}
          </div>
        </InfoWindow>
      ) : (
        <span>{'Loading'}</span>
      )}
    </React.Fragment>
  )
}

export default InfoWindowContainer
